import { QueryClient } from "@tanstack/react-query"
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactNode } from "react"

export const ReactQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false
        },
        mutations: {
            retry: false
        }
    }
})

export const ReactQueryProvider = ({ children }: { children: ReactNode }) => {
    return (
        <QueryClientProvider client={ReactQueryClient}>
            {children}
        </QueryClientProvider>
    )
}