import { FormControlLabel, RadioGroup, Radio as MuiRadio, Button, CircularProgress, Icon } from "@mui/material"
import { grey } from "@mui/material/colors"
import ReactSelect, { ActionMeta, MultiValue } from 'react-select'
import { ChangeEvent, FC, ReactNode, useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import axios, { AxiosResponse } from "axios"
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import LoadingButton from '@mui/lab/LoadingButton'
import DeleteIcon from '@mui/icons-material/Delete'
import makeAnimated from 'react-select/animated'
import ReactDatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ja } from 'date-fns/locale'
import { setHours, setMinutes } from "date-fns"
import { MultiSelectGroupProps, MultiSelectProps, SelectOption, SelectOptionGroup } from "../../Interfaces/SelectOption"
import { get } from 'lodash'

export const Label = ({ isRequire, isNoMargin, children }: { isRequire?: boolean, isNoMargin?: boolean, children: ReactNode }) => {
    const required = isRequire ? <span className="text-red-500">*</span> : ''
    const className = isNoMargin ? 'block mb-2 text-sm font-medium text-gray-500 dark:text-gray-400' : 'block mt-6 mb-2 text-sm font-medium text-gray-500 dark:text-gray-400'
    return (
        <label className={className}>
            {children}{required}
        </label>
    )
}

interface InputProps {
    name: string;
    type: string;
    disabled?: boolean;
    onChange?: (value: string) => void;  // onChangeを任意のプロパティとして追加
}
export const Input = ({ name, type, disabled = false, onChange }: InputProps) => {
    const { control, formState: { errors } } = useFormContext()
    
    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue=""
                render={({field}) => (
                    <input 
                        {...field} 
                        type={type} 
                        disabled={disabled} 
                        readOnly={disabled}
                        onChange={(e) => {
                            field.onChange(e) // react-hook-formのonChange
                            onChange?.(e.target.value) // onChangeが指定されている場合のみ実行
                        }}
                        className={
                            disabled
                                ? "shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                : "shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        }
                    />
                )}
            />
            {get(errors, name) && <span className="text-red-500 text-xs">{String(get(errors, name)?.message)}</span>}
        </>
    )
}

export const Radio = ({ name, items, onChange }: { name: string, items: { value: number | string, label: string }[], onChange?: ((event: ChangeEvent<HTMLInputElement>, checked: boolean) => void) }) => {
    const { control, formState: { errors } } = useFormContext()
    
    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue={items[0].value}
                render={({ field }) => (
                    <RadioGroup row {...field} className="dark:text-gray-300">
                        {items.map(({ value, label }, index: number) => (
                            <FormControlLabel key={index} value={value} control={<MuiRadio size="small" sx={{ color: grey[300] }} onChange={onChange}/>} label={label} />
                        ))}
                    </RadioGroup>
                )}
            />
            {get(errors, name) && <span className="text-red-500 text-xs">{String(get(errors, name)?.message)}</span>}
        </>
    )
}

// スタイルのカスタマイズ
const customStyles = {
    menuPortal: (base: any) => ({ ...base, zIndex: 1300 }), // MUIのダイアログ等と重ならないようにzIndexを設定
    menu: (base: any) => ({ ...base, zIndex: 1300 }),        // 選択肢メニューのzIndexを設定
}
export const Select = ({ name, labelName, options, placeholder, onChange, isClearable=false }: { name: string, labelName?: string, options: { value: number | string, label: string }[], placeholder?: string, onChange?: (newValue: number | string | null) => void, isClearable?: boolean }) => {
    const { control, formState: { errors }, setValue } = useFormContext()

    const handleChange = (newValue: { value: number | string, label: string }) => {
        if (newValue) {
            setValue(name, newValue.value)

            if (labelName) {
                setValue(labelName, newValue.label)
            }

            if (onChange) {
                onChange(newValue.value)
            }
        } else {
            setValue(name, null)

            if (labelName) {
                setValue(labelName, null)
            }

            if (onChange) {
                onChange(null)
            }
        }
    }

    return (
        <>
            <Controller name={name} control={control} render={({ field }) => (
                <ReactSelect 
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={options} 
                    placeholder={placeholder}
                    value={options?.find((x) => x.value === field.value)} 
                    onChange={handleChange} 
                    components={{
                        IndicatorSeparator: () => null,
                    }}
                    isClearable={isClearable}
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                />
            )}/>

            {/* ネストしたnameに対応 */}
            {get(errors, name) && <span className="text-red-500 text-xs">{String(get(errors, name)?.message)}</span>}
        </>
    )
}

export const MultiSelect: FC<MultiSelectProps> = ({ name, options, placeholder, onChange }) => {
    const { control, formState: { errors }, setValue, getValues } = useFormContext()
    const defaultOptions = options.filter((option) => getValues(name)?.includes(option.value))
    const animatedComponents = makeAnimated()

    const handleChange = (newValue: MultiValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => {
        const valueArray = newValue as SelectOption[]
        setValue(name, valueArray.map(option => option.value))
        if (onChange) {
          onChange(valueArray) // 追加のonChangeハンドラを実行
        }
    }

    return (
        <>
            <Controller name={name} control={control} render={({ field }) => (
                    <ReactSelect 
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={options} 
                        placeholder={placeholder}
                        defaultValue={defaultOptions}
                        value={options?.find((x) => x.value === field.value)} 
                        onChange={handleChange}
                        components={animatedComponents}
                        isMulti
                    />
                )}
            />
            {get(errors, name) && <span className="text-red-500 text-xs">{String(get(errors, name)?.message)}</span>}
        </>
    )
}

export const MultiSelectGroup: FC<MultiSelectGroupProps> = ({ name, optionGroups, placeholder, onChange }) => {
    const { control, formState: { errors }, setValue, getValues } = useFormContext()
    const options: SelectOption[] = [];
    optionGroups.forEach(optionGroup => {
        options.push(...optionGroup.options)
    })
    const defaultOptions = options.filter((option) => getValues(name)?.includes(option.value))
    const animatedComponents = makeAnimated()

    const handleChange = (newValue: MultiValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => {
        const valueArray = newValue as SelectOption[]
        setValue(name, valueArray.map(option => option.value))
        if (onChange) {
          onChange(valueArray) // 追加のonChangeハンドラを実行
        }
    }

    return (
        <>
            <Controller name={name} control={control} render={({ field }) => (
                    <ReactSelect 
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={optionGroups} 
                        placeholder={placeholder}
                        defaultValue={defaultOptions}
                        value={options.find((x) => x.value === field.value)} 
                        onChange={handleChange}
                        components={animatedComponents}
                        isMulti
                    />
                )}
            />
            {get(errors, name) && <span className="text-red-500 text-xs">{String(get(errors, name)?.message)}</span>}
        </>
    )
}

export const ImageUpload = ({ name, imagePath, savePath, previewWidth }: { name: string, imagePath: string, savePath: string, previewWidth: number }) => {
    const { setValue, control } = useFormContext()
    
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [previewPath, setPreviewPath] = useState<string>(imagePath)
    const [errorMessage, setErrorMessage] = useState<string>()

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploading(true)
        setErrorMessage('')

        const { files } = event.target

        if (files) {
            let formData = new FormData()
            formData.append('upload_file', files[0])
            formData.append('dir_path', savePath)

            // console.log(...formData.entries())

            axios.post('/admin_api/file_upload', formData, {
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response: AxiosResponse) => {
                // responseで保存したURLを返してもらう
                // それを利用してプレビュー表示 + hiddenのvalueにセット
                // onSubmit内の保存処理ではURLを保存させる
                const { data } = response
                setPreviewPath(data)
                setValue(name, data)
                setIsUploading(false)
            }).catch((error) => {                
                const errorResponse = error.response

                if (errorResponse.status === 422) {
                    setErrorMessage(error.response.data.message)
                } else {
                    // status 413とか
                    setErrorMessage('ファイルサイズが大きすぎる可能性があります。(2MBまで)')
                }
                
                setIsUploading(false)
            })
        }
    }

    const handleFileDelete = () => {
        const confirm = window.confirm('画像を削除してもいいですか？')
        if (confirm) {
            setPreviewPath('')
            setValue(name, '')
        }
    }

    return (
        <>
            <LoadingButton
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                className="button-type1"
                size="small"
                loading={isUploading}
                loadingPosition="start"
            >
                画像アップロード
                <input 
                    name="logo"
                    type="file"
                    onChange={handleFileUpload}
                    className="input-type-hidden" 
                />
            </LoadingButton>
            <span className="text-xs text-gray-300 relative top-2 left-2">※アップロード可能な画像は「.jpg .png .gif .webp」です</span>
            {errorMessage && <div className="text-red-500 mt-1">{errorMessage}</div>}

            <Input name={name} type="hidden" />
            {previewPath ? (
                <div className="preview" style={{ width: previewWidth }} onClick={handleFileDelete}>
                    <figure className="preview-thumb">
                        <img src={previewPath} style={{ width: previewWidth }} />
                    </figure>
                    <figcaption className="preview-caption">
                        <p><DeleteIcon />画像を削除</p>
                    </figcaption>
                </div>
            ) : ''}
        </>
    )
}

export const DebouncedInput = ({
        value: initialValue,
        onChange,
        debounce = 500,
        ...props
    }: {
        value: string | number
        onChange: (value: string | number) => void
        debounce?: number
    } & Omit<e.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <div className="relative inline-block" style={{ top: '1px' }}>
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
            </div>
            <input 
                {...props} 
                type="search" 
                value={value} 
                onChange={e => setValue(e.target.value)} 
                className="block px-4 py-1 ps-10 h-8 text-sm text-gray-900 border border-gray-300 bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                style={{ borderRadius: '0.3rem' }}
                placeholder="Search all columns..." 
            />
        </div>
    )
}

export const TextArea = ({ name, placeholder = '' }: { name: string, placeholder?: string }) => {
    const { control, formState: { errors } } = useFormContext()
    
    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <textarea
                        placeholder={placeholder}
                        rows={4}
                        onChange={onChange}
                        value={value}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                )}
            />
            {get(errors, name) && <span className="text-red-500 text-xs">{String(get(errors, name)?.message)}</span>}
        </>
    )
}

export const DatePicker = ({ name }: {name: string}) => {
    const { control, formState: { errors }, setValue } = useFormContext()
    registerLocale('ja', ja);
    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field: { value } }) => (
                    <ReactDatePicker
                        selected={value}
                        onChange={(date) => {
                            if (date) {
                                const adjustedDate = setHours(setMinutes(date, 0), 12)//UTCの正午に設定
                                setValue(name, adjustedDate)
                            } else {
                                setValue(name, date)
                            }
                        }}
                        dateFormat="yyyy/MM/dd"
                        locale='ja'
                        isClearable
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    />
                )}
            />
        </>
    )
}