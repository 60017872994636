
export const getThisMonth = () => {
    const date = new Date()
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // 月は0始まりなので+1
    return `${year}-${month}`
}

// 202409のように6桁の文字列をフォーマットする
export const formatDateString = (dateString: string): string => {
    if (dateString.length !== 6) {
        throw new Error("Invalid date string length. Expected 6 characters.")
    }

    const year = dateString.slice(0, 4)
    const month = dateString.slice(4, 6)

    return `${year}/${month}`
}

export const getCurrentTimestamp = () => {
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0') // 月は0から始まるので+1
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const seconds = String(now.getSeconds()).padStart(2, '0')

    return `${year}${month}${day}_${hours}${minutes}${seconds}`
}