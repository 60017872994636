import { ThemeProvider as MuiThemeProvider, createTheme, useMediaQuery } from '@mui/material'
import { ReactNode, useMemo } from 'react'

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
    // dark modeのデザイン調整に時間がかかるため、一旦light modeのみとする
    // const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
    const isDarkMode = false
    const mode = isDarkMode ? 'dark' : 'light'
    const theme = useMemo(() => createTheme(getDesignTokens(mode)), [isDarkMode])

    return (
        <MuiThemeProvider theme={theme}>
            {children}
        </MuiThemeProvider>
    )
}

const getDesignTokens = (mode: 'light' | 'dark') => ({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            primary: {
              main: '#1976d2',
            },
            secondary: {
              main: '#dc004e',
            },
            background: {
              default: '#ffffff',
              paper: '#ffffff',
            },
          }
        : {
            primary: {
              main: '#90caf9',
            },
            secondary: {
              main: '#f48fb1',
            },
            background: {
              default: '#121212',
              paper: '#121212',
            },
          }),
    },
    chart: {
      colors: mode === 'dark' ? ['#718596', '#a0a4c7', '#e9e9e9', '#c5bfb9', '#948f89'] : ['#1567db', '#53d0c6', '#c2c8d1', '#f9837c', '#af7af4'],
    },
})
// const getDesignTokens = () => {
//     const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

//     return {
//         palette: {
//             mode: isDarkMode ? 'dark' : 'light'
//         },
//         pieChart: {
//             colors: isDarkMode ? ['#718596', '#a0a4c7', '#e9e9e9', '#c5bfb9', '#948f89'] : ['#00215d', '#00468b', '#0071bc', '#589fef', '#8fd0ff'],
//         },
//     }
// }